/* Experience */

.experience {
  padding: 0.7rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-orange);
}

.experience-light h1 {
  text-transform: uppercase;
  color: var(--primary-blue-light);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.marker-light {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid #cacaca;
  background: var(--primary-blue-light);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content-light {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-green-light);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-red);
  font-weight: 900;
  font-size: 23px;
}

.timeline-content-light h3 {
  margin-top: 0.3125rem;
  color: var(--primary-green-light);
  font-weight: 900;
  font-size: 23px;
}

/*    Media   */
@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

/* contacts component  */

.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts-light {
  padding: 3rem 0;
  background: var(--primary-light-grey);
  .line {
    background: var(--primary-green-light) !important;
  }
}

.contacts h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  transform: uppercase;
}

.contacts-light h1 {
  color: var(--primary-blue-light);
  padding: 1.3rem 0;
  transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

.contacts-light p {
  color: var(--primary-blue-light);
  font-size: 1.2rem;
}

.contact-btn-light {
  background: #2e8b57 !important;
  width: 100%;
  border: 0px !important;
  border-radius: 5px !important;
  font-size: 22px;
  margin: 20px 0px 20px 0px !important;
}

.contact-btn-light:hover {
  opacity: 0.8;
}

/* inputs */

.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 00.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts-light input,
.contacts-light textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 00.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: #000;
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125 solid var(--primary-dark-grey);
}

.contacts-light input:focus,
.contacts-light textarea:focus {
  background: transparent;
  color: #000;
  border-bottom: 0.125 solid var(--primary-green-light);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contacts-light textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control-BW {
  margin: -0.3rem 0;
}

textarea.form-control-BW {
  margin: -0.3rem 0;
}

/*   media  */
@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}

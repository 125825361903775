.RightSider {
  background-color: #30323e;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 10px 30px #1a1a1a;
  box-shadow: 0 10px 30px #1a1a1a;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  .card-blog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
  .ant-card.ant-card-bordered {
    width: 280px;
    height: 300px;
    background: #30323e;
    box-shadow: 0px 10px 25px #1a1a1a;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  img.imgae {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    span.anticon {
      color: #b4bac3;
    }
    .anticon svg {
      width: 20px;
      height: 20px;
    }
    .anticon svg:hover {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      color: #3b5998;
      cursor: pointer;
      text-shadow: 0 0 15px #3b5998, 0 0 30px #3b5998, 0 0 40px #3b5998;
    }
  }
  .card h2 {
    color: #b4bac3;
  }
  .card h3 {
    color: #b4bac3;
  }
  .categories {
    width: 100%;
    margin-top: 20px;
    .item-category {
      border: 1px solid #3d414b;
      border-radius: 5px;
      height: 60px;
      cursor: pointer;
      margin: 20px;
      justify-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #b4bac3;
      font-size: large;
      font-weight: 400;
      ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: #b4bac3;
        -webkit-transform: skewX(45deg) translateX(400px);
        transform: skewX(45deg) translateX(400px);
        -webkit-transition: 0.8s;
        transition: 0.8s;
      }
    }
    .label {
      margin-right: 15px;
    }
    .item-category:hover {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
      -webkit-box-shadow: 0 5px 15px #1a1a1a;
      box-shadow: 0 5px 15px #1a1a1a;
      border-radius: 5px;
    }
  }
}

.RightSider-light {
  background-color: #c0c0c0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 10px 30px #1a1a1a;
  box-shadow: 0 10px 30px #1a1a1a;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  .card-blog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
  .ant-card.ant-card-bordered {
    width: 280px;
    height: 300px;
    background: #c0c0c0;
    box-shadow: 0px 10px 25px #1a1a1a;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  img.imgae {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    span.anticon {
      color: var(--primary-blue-light);
    }
    .anticon svg {
      width: 20px;
      height: 20px;
    }
    .anticon svg:hover {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      color: #3b5998;
      cursor: pointer;
      text-shadow: 0 0 15px #3b5998, 0 0 30px #3b5998, 0 0 40px #3b5998;
    }
  }
  .card h2 {
    color: var(--primary-blue-light) !important;
  }
  .card h3 {
    color: var(--primary-blue-light) !important;
  }
  .categories {
    width: 100%;
    margin-top: 20px;
    .item-category {
      border: 1px solid #3d414b;
      border-radius: 5px;
      height: 60px;
      cursor: pointer;
      margin: 20px;
      justify-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary-blue-light);
      font-size: large;
      font-weight: 400;
      ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background: #b4bac3;
        -webkit-transform: skewX(45deg) translateX(400px);
        transform: skewX(45deg) translateX(400px);
        -webkit-transition: 0.8s;
        transition: 0.8s;
      }
    }
    .label {
      margin-right: 15px;
    }
    .item-category:hover {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
      -webkit-box-shadow: 0 5px 15px #1a1a1a;
      box-shadow: 0 5px 15px #1a1a1a;
      border-radius: 5px;
    }
  }
}

.main {
  background: #282b35;
  width: 100%;
  height: 100%;
  overflow: auto;
  .card {
    background: #2f313d;
    border-radius: 10px;
    height: 530px;
    margin: 20px;
    border: 1px solid #3d414b;
    -webkit-box-shadow: 0px 10px 25px #1a1a1a;
    box-shadow: 0px 10px 25px #1a1a1a;
    .img {
      height: 400px;
      margin-left: 5px;
      margin-right: 5px;
    }
    img.main-img {
      border-radius: 10px;
      width: 100%;
      height: 400px;
    }
  }

  .footer-BLOG {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ordeer-btn {
      border: 2px solid #3d414b;
      border-radius: 10px;
      height: 38px;
      margin-right: 20px;
      width: 120px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      color: #f5f7fa;
      -webkit-box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
      box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
      background: #30323e;
    }
    .ordeer-btn:hover {
      border: 2px solid #f5f7fa;
      -webkit-box-shadow: 0px 10px 25px #1a1a1a;
      box-shadow: 0px 10px 25px #1a1a1a;
      border-radius: 10px;
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

.main-light {
  background: #d3d3d3;
  width: 100%;
  height: 100%;
  overflow: auto;
  .card {
    background: #778899;
    border-radius: 10px;
    height: 530px;
    margin: 20px;
    border: 1px solid #778899;
    -webkit-box-shadow: 0px 10px 25px #1a1a1a;
    box-shadow: 0px 10px 25px #1a1a1a;
    .img {
      height: 400px;
      margin-left: 5px;
      margin-right: 5px;
    }
    img.main-img {
      border-radius: 10px;
      width: 100%;
      height: 400px;
    }
  }

  .footer-BLOG {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ordeer-btn {
      border: 2px solid var(--primary-blue-light);
      border-radius: 10px;
      height: 38px;
      margin-right: 20px;
      width: 120px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      color: #f5f7fa;
      -webkit-box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
      box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
      background: var(--primary-green-light);
    }
    .ordeer-btn:hover {
      border: 2px solid #f5f7fa;
      -webkit-box-shadow: 0px 10px 25px #1a1a1a;
      box-shadow: 0px 10px 25px #1a1a1a;
      border-radius: 10px;
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

.header {
  height: 50px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-size: large;
  img.img-nain {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
  }
}
.header-MAIN {
  margin: 20px;
  position: relative;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  color: var(--primary-blue-light);
  justify-content: center;
  background: #c0c0c0;
  border-radius: 5px;
  -webkit-box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
  box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
  button.ant-btn.ant-btn-default.home-page {
    background: #b4bac3;
    border-radius: 5px;
    height: 32px;
    width: 120px;
    color: #30323e;
    border: none;
    font-weight: 600;
    font-size: 16px;
  }
  button.ant-btn.ant-btn-default.home-page:hover {
    opacity: 0.5;
  }
  .des {
    color: var(--primary-blue-light);
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding-right: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clear {
    background: #b4bac3;
    border-radius: 5px;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .clear:hover {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

.header-MAIN {
  margin: 20px;
  position: relative;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  color: var(--primary-blue-light);
  justify-content: center;
  background: #30323e !important;
  border-radius: 5px;
  -webkit-box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
  box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
  button.ant-btn.ant-btn-default.home-page {
    background: #b4bac3;
    border-radius: 5px;
    height: 32px;
    width: 120px;
    color: #30323e;
    border: none;
    font-weight: 600;
    font-size: 16px;
  }
  button.ant-btn.ant-btn-default.home-page:hover {
    opacity: 0.5;
  }
  .des {
    color: #b4bac3 !important;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding-right: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clear {
    background: #b4bac3;
    border-radius: 5px;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .clear:hover {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

.header-MAIN-light {
  margin: 20px;
  position: relative;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  background: #778899 !important;
  border-radius: 5px;
  -webkit-box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
  box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
  button.ant-btn.ant-btn-default.home-page {
    background: #b4bac3;
    border-radius: 5px;
    height: 32px;
    width: 120px;
    color: #30323e;
    border: none;
    font-weight: 600;
    font-size: 16px;
  }
  button.ant-btn.ant-btn-default.home-page:hover {
    opacity: 0.5;
  }
  .des {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding-right: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clear {
    background: #b4bac3;
    border-radius: 5px;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .clear:hover {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

.header-light {
  height: 50px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-size: large;
  img.img-nain {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
  }
}
.header-MAIN {
  margin: 20px;
  position: relative;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  color: var(--primary-blue-light);
  justify-content: center;
  background: #778899;
  border-radius: 5px;
  -webkit-box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
  box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
  button.ant-btn.ant-btn-default.home-page {
    background: #b4bac3;
    border-radius: 5px;
    height: 32px;
    width: 120px;
    color: #30323e;
    border: none;
    font-weight: 600;
    font-size: 16px;
  }
  button.ant-btn.ant-btn-default.home-page:hover {
    opacity: 0.5;
  }
  .des {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding-right: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clear {
    background: #b4bac3;
    border-radius: 5px;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .clear:hover {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #b4bac3;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.sidebar {
  background-color: #30323e;
  width: 100%;
  height: 100%;
  box-shadow: 0 10px 30px #1a1a1a;
  -webkit-box-shadow: 0 10px 30px #1a1a1a;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: auto;
  .logo {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #3d414b;
  }
  .item {
    font-size: large;
    color: #b4bac3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 25px;
    margin: 20px;
    height: 60px;
    cursor: pointer;
  }
  .item-open {
    -webkit-box-shadow: 0px 10px 25px #1a1a1a;
    box-shadow: 0px 10px 25px #1a1a1a;
    border-radius: 10px;
    padding: 5px;
    border-left: 5px solid #ef2a57;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-left: 25px;
    margin: 20px;
    height: 60px;
    font-size: large;
    color: #b4bac3;
  }
  .item:hover {
    -webkit-box-shadow: 0px 10px 25px #1a1a1a;
    box-shadow: 0px 10px 25px #1a1a1a;
    border-radius: 10px;
    padding: 5px;
    height: 60px;
  }
  .imge-div {
    margin-right: 10px;
  }
  .imge-div img {
    border-radius: 5px;
  }
}

.sidebar-light {
  background-color: #c0c0c0;
  width: 100%;
  height: 100%;
  box-shadow: 0 10px 30px #1a1a1a;
  -webkit-box-shadow: 0 10px 30px #1a1a1a;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: auto;
  .logo {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #3d414b;
  }
  .item {
    font-size: large;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #778899;
    -webkit-box-shadow: 0px 10px 25px #1a1a1a;
    box-shadow: 0px 10px 25px #1a1a1a;
    border-radius: 10px;
    padding-left: 25px;
    margin: 20px;
    height: 60px;
    cursor: pointer;
  }
  .item-open {
    -webkit-box-shadow: 0px 10px 25px #1a1a1a;
    box-shadow: 0px 10px 25px #1a1a1a;
    border-radius: 10px;
    padding: 5px;
    border-left: 5px solid var(--primary-blue-light);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-left: 25px;
    margin: 20px;
    height: 60px;
    font-size: large;
    background: var(--primary-green-light);
    color: #fff;
  }
  .item:hover {
    border-left: 5px solid var(--primary-blue-light);
    background: var(--primary-green-light);
    padding: 5px;
    height: 60px;
  }
  .imge-div {
    margin-right: 10px;
  }
  .imge-div img {
    border-radius: 5px;
  }
}

// responsove portfolio page
@media only screen and (min-width: 500px) and (max-width: 912px) {
  .div3 {
    display: none;
  }
  .div1 {
    width: 30% !important;
  }
  .div2 {
    width: 70% !important;
  }
}

@media only screen and (max-width: 499px) {
  .mainDiv {
    height: 100% !important;
  }
  .header-MAIN {
    height: 40px !important;
    margin: 10px 20px 0px 20px !important;
  }
  .header {
    height: 35px !important;
  }
  .header-light {
    height: 35px !important;
  }
  .sidebar .logo {
    height: 60px !important;
  }
  .mainDiv {
    .div1 {
      width: 100% !important;
      height: 28vh;
      display: none;
      // overflow: auto;
      .data-menu {
        overflow: auto !important;
        height: 100%;
      }
    }
    .div2 {
      width: 100% !important;
    }
    .div3 {
      width: 100% !important;
    }
  }
  .main {
    height: 100% !important;
    .card {
      height: 250px !important;
    }
  }
  .main-light {
    height: 100% !important;
    .card {
      height: 250px !important;
    }
  }
  .main .card .img {
    height: 160px !important;
  }
  .main .card img.main-img {
    height: 160px !important;
  }
  .main-light .card .img {
    height: 160px !important;
  }

  .main-light .card img.main-img {
    height: 160px !important;
  }
  .header-MAIN .des {
    padding-right: 0px !important;
    font-size: 14px !important;
  }
  .RightSider {
    height: 100% !important;
  }
  .mainDiv .div3 {
    display: none;
  }
  .main .footer-BLOG .ordeer-btn {
    height: 30px;
  }
  .main .footer-BLOG {
    height: 35px;
  }
  .main-light .footer-BLOG .ordeer-btn {
    height: 30px;
  }
  .main-light .footer-BLOG {
    height: 35px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(48, 48, 46);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0, 7);
  --primary-blue-light: #191970;
  --primary-green-light: #2e8b57;
}
body {
  font-family: "Roboto Condensed", sans-serif !important;
}

/* Navbar design   */

nav {
  z-index: 2;
}
.Logo {
  width: 16.25rem;
}

nav a.nav-link {
  /* font-weight: 500; */
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

.bg-light {
  background: #c0c0c0 !important;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.active {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-orange) !important;
}

.text-light {
  color: #191970 !important;
  font-weight: 600;
}

.loadingContainer {
  height: 100vh;
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #282b35;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  z-index: 100000;
}
.loadingContainerLight {
  height: 100vh;
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  z-index: 100000;
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

.nav.navbar.navbar-expand-lg.navbar-light.bg-dark.fixed-top {
  position: sticky !important;
  height: 90px !important;
}

/* header */
.header-wraper {
  position: relative;
  background: url(wall-laptop.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.header-wraperLight {
  position: relative;
  background: url("./images/lightModeBG4.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  filter: brightness(0.8);
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-orange);
  text-transform: uppercase;
}

/* typed text */

.typed-text {
  font-size: 2.2rem;
  color: var(--primary-white);
}

.typed-text-light {
  font-size: 2.2rem;
  color: #191970 !important;
  font-weight: 500;
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}
/*  header main offer button */

.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer-light {
  text-transform: uppercase;
  border-radius: 5px;
  background-color: #2e8b57;
  padding: 0.625rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition: 0.2s ease-in-out;
}

.btn-main-offer-light:hover {
  text-decoration: none;
  /* background-color: var(--primary-hover-red); */
  color: var(--primary-white);
  transition: 0.2s ease-in-out;
  opacity: 0.8;
}
/*  Practice js  */

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

.particles-canvas-light canvas.tsparticles-canvas-el {
  pointer-events: none !important;
}

@media (max-width: 768px) {
  /* .particles-canvas {
    display: none;
  } */
}

/* About Me */
.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid var(--primary-light-grey);
}

/* @media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
} */

/*   Portfolio  */

.portfolio-wrapper {
  background: var(--primary-light-grey);
  padding: 3rem 0;
}

.portfolio-wrapper h1 {
  color: var(--primary-orange);
}

.portfolio-image {
  width: 15rem;
  border: 1px solid var(--primary-black);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
  display: flex;
}

.popupbox-wrapper {
  margin-top: 7rem;
}

/*   overflow box */
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 7rem;
  top: 0;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: 6rem;
  margin-top: 2.6rem;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/*   overflow box  */
.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-orange);
}

.hyper-link:hover {
  color: var(--primary-red);
}
.popupbox-titleBar {
  content-visibility: hidden;
}

span.errorMessage {
  color: red;
  margin-top: 10px !important;
}

/*    media  */
@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }

  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }

  .portfolio-image-popupbox {
    width: 30%;
  }
}

/*  testimonials  */

.testimonials {
  background-image: url(./images/testimonials-bg.jpg);
  background-size: cover;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
  height: 300px;
}

.testimonials-light {
  background-image: url(./images/portfolio2.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
  height: 300px;
}

.testimonials h1 {
  color: var(--primary-orange);
  padding: 3rem 0;
  text-transform: uppercase;
}

.testimonials-light h1 {
  color: var(--primary-blue-light);
  padding: 3rem 0;
  font-weight: bold;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: var(--primary-transparent-black);
}

.testimonials-content-light {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: var(--primary-transparent-black);
}

.myCarousel {
  color: var(--primary-white);
  background: transparent;
  margin-top: -6%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 17.875rem;
}

.myCarousel h3 {
  color: var(--primary-orange);
  font-weight: 600;
  text-transform: uppercase;
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}

.carousel-root {
  margin: auto;
  margin-top: 3% !important;
}

/* Section 3 */
.section-3 {
  width: 100%;
  height: 100%;
  background-color: var(--black-color);
  flex-direction: column;
  padding: 15rem 0;
  clip-path: polygon(68% 0, 100% 0, 100% 97%, 38% 100%, 0 100%, 0 3%);
}

/* Section 3 Heading */
.section-3-heading {
  font-size: 10rem;
  font-weight: 200;
  color: var(--primary-color);
  margin-bottom: 15rem;
}
/* End of Section 3 Heading */

/* Section 3 Content */
.section-3-content {
  width: 80%;
  flex-direction: column;
  perspective: 1000rem;
  position: relative;
}

/* Section 3 Images */
.section-3-content img {
  width: inherit;
}

.macbook-img-1 {
  transform: rotateX(-80deg);
  transform-origin: bottom;
}

.change .macbook-img-1 {
  transform: rotateX(0);
  transition: transform 3s;
}
/* End of Section 3 Images */

/* Section 3 Loading */
.loading-wrapper {
  position: absolute;
  top: 6%;
  width: 60%;
  height: 85%;
  background: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(images/cars.png) center no-repeat;
  z-index: -1;
  opacity: 0;
}

.change .loading-wrapper {
  opacity: 1;
  transition: opacity 1s 2s;
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--black-color);
  flex-direction: column;
}

.change .loading {
  opacity: 0;
  transition: 1s 5s;
}

.loading i {
  font-size: 10rem;
  color: var(--white-color);
  margin-bottom: 2rem;
}

.progress-bar {
  width: 22rem;
  height: 0.3rem;
  background-color: #888;
  border-radius: 5rem;
  position: relative;
}

.progress-bar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--white-color);
}

.change .progress-bar::after {
  width: 100%;
  transition: width 1s 3s;
}
/* End of Section 3 Loading */

/* Section 3 Info */
.macbook-info {
  position: absolute;
  width: 70rem;
  text-align: center;
  opacity: 0;
}

.change .macbook-info {
  opacity: 1;
  transition: opacity 1s 6s;
}

.macbook-info-heading {
  font-size: 6rem;
  font-weight: 400;
  color: var(--primary-color);
}

.macbook-price {
  font-size: 2.5rem;
  font-weight: 200;
  margin: 3rem 0;
}

.macbook-btn {
  width: 25rem;
  height: 5rem;
  background-image: linear-gradient(#ddd, #fff);
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.1rem #fff, -0.1rem -0.1rem 0.1rem #fff;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  color: var(--primary-color);
  cursor: pointer;
}
/* End of Section 3 Info */
/* End of Section 3 Content */
/*End of Section 3 */

.promo-img:hover {
  opacity: 0.7;
}

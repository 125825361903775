/* Services  */
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

button.ant-btn.ant-btn-default.portfolio-btn {
  background: #b4bac3;
  border-radius: 5px;
  height: 40px;
  width: 160px;
  /* color: ; */
  border: none;
  font-weight: 600;
  font-size: 16px;
}
button.ant-btn.ant-btn-default.portfolio-btn:hover {
  background: #f9ab00;
  color: #fff;
}

button.ant-btn.ant-btn-default.portfolio-light-btn {
  background: #2e8b57;
  border-radius: 5px;
  height: 40px;
  width: 160px;
  /* color: ; */
  border: none;
  font-weight: 600;
  font-size: 16px;
}
button.ant-btn.ant-btn-default.portfolio-light-btn:hover {
  opacity: 0.8;
  color: #fff;
}

.services h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .circle-light {
  position: relative;
  margin: 0.375rem auto;
  background: #191970;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box-back {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}

.box-back-light:hover {
  background: #dcdcdc;
  border-bottom: 0.3125rem solid #2e8b57;
  color: #000;
}

.box-back-light h3 {
  background: #2e8b57;
  border-radius: 10px;
  margin: 10px;
  color: #fff;
}

.box-back-light {
  height: 16rem;
  border-bottom: 0.3125rem solid #2e8b57;
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}

.box-back:hover {
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid var(--primary-dark);
  color: #fff;
}

.box-back h3 {
  background: #30323e;
  border-radius: 10px;
  margin: 10px;
  color: #fff;
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}

.services .box-light {
  height: 16rem;
  border-bottom: 0.3125rem solid #191970;
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}

.services .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box-light:hover {
  background: #dcdcdc;
  border-bottom: 0.3125rem solid #2e8b57;
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box-light:hover .icon {
  color: #c0c0c0;
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

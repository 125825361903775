// dark mode
// navbar
.nav-dark {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px 0px 50px;
  background: #343a40 !important;
  position: sticky;
  z-index: 100;
  top: 0;
  right: 0;
  .navigationDark {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .nav-item {
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;
      padding: 7px;
      border-top: 0.1875rem solid var(--primary-dark);
      cursor: pointer;
    }
    .nav-item:hover {
      border-top: 0.1875rem solid var(--primary-orange);
      opacity: 0.8;
    }
  }
}

.logo-contanier {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
}
.modeContainer {
  cursor: pointer;
}

//light mode navbar
.nav-light {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px 0px 50px;
  background: #c0c0c0 !important;
  box-shadow: 3px 0 10px 0 rgb(183 192 206 / 20%);
  position: sticky;
  z-index: 100;
  top: 0;
  right: 0;
  .naviigationLight {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .nav-item {
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      color: #191970;
      padding: 7px;
      border-top: 0.1875rem solid #191970;
      cursor: pointer;
    }
    .nav-item:hover {
      border-top: 0.1875rem solid #2e8b57;
      opacity: 0.8;
    }
  }
  .active {
    color: #2e8b57 !important;
    border-top: 0.1875rem solid #2e8b57 !important;
  }
}

// menu for mobile
.hamburger-contanier {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #c0c0c0;
}

.hamburger-contanier-light {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #343a40;
}

.mobile-icon-container {
  display: flex;
  gap: 30px;
  align-items: center;
}

// footer
/*  Footer    */

.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer-light {
  background: #c0c0c0;
  color: var(--primary-blue-light);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
  font-size: 18px;
}

.footer-light a {
  text-decoration: none;
  color: var(--primary-blue-light);
  font-size: 18px;
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
}

.footer-light a:hover {
  text-decoration: none;
  color: var(--primary-green-light);
}

.footer p {
  margin-bottom: 0;
}

// responsive
@media (max-width: 1050px) {
  .logo-contanier {
    width: 130px;
  }
}

@media (max-width: 980px) {
  .logo-contanier {
    width: 200px !important;
  }
  .dark-drawer {
    margin-top: 10px;
    .ant-drawer-header {
      background: #343a40 !important;
      .ant-drawer-title {
        color: #fff !important;
        font-size: 26px;
        display: flex;
        justify-content: center;
      }
      button.ant-drawer-close {
        color: #fff !important;
        font-size: 26px;
      }
    }
    .ant-drawer-body {
      padding: 0px !important;
      background: #343a40;
      .navigationDark {
        .nav-item {
          height: 50px;
          font-size: 22px;
          display: flex;
          color: #fff;
          align-items: center;
          justify-content: center;
        }
        .active {
          color: var(--primary-orange) !important;
        }
      }
    }
  }

  .light-drawer {
    .ant-drawer-header {
      background: #c0c0c0 !important;
      .ant-drawer-title {
        color: #191970 !important;
        font-size: 26px;
        display: flex;
        justify-content: center;
      }
      button.ant-drawer-close {
        color: #191970 !important;
        font-size: 26px;
      }
    }
    .ant-drawer-body {
      padding: 0px !important;
      background: #c0c0c0;
      .naviigationLight {
        margin-top: 10px;
        .nav-item {
          color: #191970 !important;
          font-size: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .active {
          border-top: 0.1875rem solid #2e8b57 !important;
          color: #2e8b57 !important;
        }
      }
    }
  }
}
